import { CompanySidebar } from 'common/components/Layout/CompanySidebar'
import { ValidatorSidebar } from 'common/components/Layout/ValidatorSidebar'
import styled from 'styled-components'

import dynamic from 'next/dynamic'
import { userCategoryEnum } from 'services/auth/auth.enums'
import { auth } from 'services/auth/auth.singleton'
export default dynamic(() => Promise.resolve(Sidebar), {
  ssr: false,
})

const Sidebar = () => {
  const user = auth.getAuthenticatedUser()

  const isRecruiter = [userCategoryEnum.RECRUITER].includes(user?.category || userCategoryEnum.RECRUITER)
  const isValidator = [userCategoryEnum.ADMIN, userCategoryEnum.VALIDATOR].includes(
    user?.category || userCategoryEnum.RECRUITER,
  )

  return (
    <Aside className="flex flex-column align-items-center h-screen">
      {isRecruiter && <CompanySidebar />}
      {isValidator && <ValidatorSidebar />}
    </Aside>
  )
}

const Aside = styled.aside`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid #ecedee;
  .disabled {
    color: ${({ theme }) => theme.colors.gray_55};
  }
  ul {
    list-style: none;
  }
  > ul > li {
    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      padding: 0.875rem;

      &.active {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.primary_100};
      }
      &.disabled {
        opacity: 0.5;
      }

      &:hover:not(.active, .disabled) {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.gray_10};
      }
    }
  }
`
