import DefaultImage from 'assets/icons/DefaultImage.svg'
import GearSixIcon from 'assets/icons/GearSixIcon.svg'
import SignOutIcon from 'assets/icons/SignOutIcon.svg'
import { ProfileAvatar } from 'common/components/Displays/ProfileAvatar'
import { LoadingBox } from 'common/components/States/Loading/LoadingBox'
import { pagesPaths } from 'common/router/routes.utils'
import { nextImageLoaderProp } from 'common/utils/misc'
import Image from 'next/image'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { useGetUserProfile } from 'services/account/account.service'
import { auth } from 'services/auth/auth.singleton'
import styled from 'styled-components'
import { useGetCompany } from '../company.service'

export const CompanyMenu = () => {
  const [show, setShow] = useState(false)

  const { data: profile, isLoading } = useGetUserProfile()
  const ref = useRef(null)
  const { company, isLoading: isLoadingCompany } = useGetCompany()

  useClickAway(ref, () => show && setShow(false))

  const onToggle = () => setShow((c) => !c)
  const onLogout = async () => {
    await auth.clientLogout()
    window.location.replace(pagesPaths.login)
  }

  if (isLoading || isLoadingCompany) return <LoadingBox size={24} minHeight="2rem" />

  const { email, firstName, lastName } = profile || { email: '', firstName: '', lastName: '' }
  return (
    <Div ref={ref}>
      <div onClick={onToggle} className="flex align-items-center gap-2 cursor-pointer">
        <ProfileAvatar className="border-circle">
          {company.logo_file_path ? (
            <Image
              className="border-circle"
              width={28}
              height={28}
              src={company.logo_file_path}
              alt="UserIMg"
              loader={nextImageLoaderProp}
              unoptimized
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <DefaultImage />
          )}
        </ProfileAvatar>
        <div className="flex-grow-1 max-w-8rem text-overflow-ellipsis white-space-nowrap overflow-hidden">
          {company?.name}
        </div>
        <i className="pi pi-angle-up ml-auto"></i>
      </div>

      {show && (
        <div className="company-menu">
          <div className="company-details p-3 border-bottom-1 border-300">
            <div className="company-details-user font-medium text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden">
              {firstName} {lastName}
            </div>
            <div
              title={email}
              className="company-details-email text-xs text-overflow-ellipsis white-space-nowrap overflow-hidden"
            >
              {email}
            </div>
          </div>

          <ul className="flex flex-column">
            {profile && (
              <li>
                <Link href="/settings">
                  <GearSixIcon /> Settings
                </Link>
              </li>
            )}
            {/* 
            <li>
              <Link href="/help">
                <LifebuoyIcon /> Help
              </Link>
            </li> */}
            <li>
              <span className="cursor-pointer" onClick={onLogout}>
                <SignOutIcon /> Sign Out
              </span>
            </li>
          </ul>
        </div>
      )}
    </Div>
  )
}
const Div = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.black};

  .company-menu {
    position: absolute;
    bottom: 3.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    max-width: 100%;

    .company-details-email {
      color: ${({ theme }) => theme.colors.gray_70};
    }

    > ul li {
      padding: 0.5rem 1rem;

      &:hover:not(.active) {
        background-color: ${({ theme }) => theme.colors.gray_10};
      }

      > * {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding-block: 0.5rem;
      }
    }
  }
`
