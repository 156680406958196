import { ILazyParams } from 'common/types/table.types'
import { isUUID } from 'common/utils/validation/regexes'
import { IPageFilters } from 'services/filters/filters.types'
import { IGetJobResponse, IGetJobsResponse, IJob, IJobFilters } from 'services/jobs/jobs.types'
import { createURLWithFilters, createURLWithFiltersHiredPeople } from 'services/jobs/jobs.utils'
import { IHiredPeople } from 'services/seafarers/seafarers.types'
import { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { http } from '../http'
import { getOrCreateJobContent } from './jobs.api'

export const ApiPathsJobs = {
  getJobs: (query: string) => `/jobs${query}`,
  getPublicJobs: (query: string) => (query ? `/web/public/jobs${query}` : null),
  getPublicJobsCount: (jobCategories: string[]) => {
    let query = `/web/public/jobs/jobs-counts`
    if (jobCategories.length) {
      query += `?jobCategories=${jobCategories.join(',')}`
    }
    return query
  },
  jobs: `/jobs`,
  companyJobs: (add: string) => `/jobs${add}`,
  allActiveJobsSlugs: '/web/public/jobs/active-jobs-slugs',
  latestAddedJobs: '/web/public/jobs/last-jobs',
  job: (id: string) => `/jobs/${id}`,
  jobBySlug: (slug: string) => `/jobs/slug/${slug}`,
  deleteMultipleJobs: '/jobs/bulk-delete',
  jobOffer: (jobId: string) => `/jobApplication/${jobId}/inquiry`,
  applicationStatus: (applicationId: string) => `/jobApplication/${applicationId}`,
  candidates: (filters: string) => `/candidates${filters}`,
  contentCreator: `/content-creator`,
  autoFiller: (positionId: string, vesselId: string) => `auto-filler/job-requirements/${positionId}/${vesselId}`,
  jobApplicationNotes: (id: string) => `/jobApplications/${id}/notes`,
  jobApplicationNote: (id: string) => `/jobApplications/notes/${id}`,
  jobApplication: (id: string) => `/jobApplication/${id}`,
  jobApplicationViewed: (companyId: string, jobApplicationId: string) =>
    `/companies/${companyId}/board/jobApplication/${jobApplicationId}/viewed`,
  currentAPIKey: '/companies/apiKey/currentKey',
  generateAPIKey: '/companies/apiKey/generate',
  deleteAPIKey: '/companies/apiKey/delete',
}

export const getResource = async <T>(path: string): Promise<T> => {
  const { data } = await http.get(path)
  return data as T
}

export const useGetHiredPeople = (
  filters?: IJobFilters | ILazyParams | IPageFilters,
  config: SWRConfiguration<IHiredPeople> = {},
) => {
  let filtersToApply = createURLWithFiltersHiredPeople(filters)

  const { data, isLoading, error } = useSWRImmutable(
    ApiPathsJobs.candidates(filtersToApply),
    getResource<IHiredPeople>,
    config,
  )

  return { data, isLoading, error }
}

export const useGetJobBySlug = (jobSlug: string | null | undefined, config: SWRConfiguration<any> = {}) => {
  const { data, error, mutate } = useSWRImmutable(
    !isUUID(jobSlug as string) ? ApiPathsJobs.jobBySlug(jobSlug as string) : ApiPathsJobs.job(jobSlug as string),
    getResource<IGetJobResponse>,
    {
      ...config,
    },
  )

  return {
    job: data,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

export const useGetLatestJobs = (config: any = {}) => {
  const { data, error, mutate } = useSWRImmutable(ApiPathsJobs.latestAddedJobs, getResource<IJob[]>, {
    refreshInterval: 60 * 60 * 1000,
    ...config,
  })
  return {
    data,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

export const useGetCompanyJobs = (
  filters?: IJobFilters,
  // config: SWRConfiguration | Record<string, never> = {}
  config: any = {},
) => {
  let filtersToApply = createURLWithFilters(filters)
  const { data, error, mutate } = useSWRImmutable(
    filters?.companyId ? ApiPathsJobs.companyJobs(filtersToApply) : null,
    getResource<IGetJobsResponse>,
    {
      ...config,
    },
  )

  return {
    data: data?.data,
    meta: data?.meta,
    error,
    mutate,
    isLoading: !data && !error,
  }
}

export const deleteJob = async (url: string, { arg }: { arg: any }): Promise<any> => {
  const { data } = await http.delete(`${url}/${arg}`)

  return data
}

export const postJob = async (url: string, { arg }: { arg: any }): Promise<any> => {
  const { data } = await http.post(url, arg)

  return data
}

export const patchJob = async (url: string, { arg }: { arg: any }): Promise<any> => {
  const { data } = await http.patch(`${url}/${arg.id}`, arg)

  return data
}

export const useAddJob = (config: SWRMutationConfiguration<IJob, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.jobs, postJob, { ...config })

  return { trigger, isMutating }
}

export const useGetOrAddJobContent = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.contentCreator, getOrCreateJobContent, { ...config })

  return { trigger, isMutating }
}

export const useUpdateJob = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.jobs, patchJob, { ...config })

  return { trigger, isMutating }
}

const deleteMultipleJobs = async (url: string, { arg }: { arg: any }): Promise<any> => {
  const { data } = await http.post(`${url}`, arg)

  return data
}

export const useDeleteMultipleJobs = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsJobs.deleteMultipleJobs, deleteMultipleJobs, { ...config })

  return { trigger, isMutating }
}

export const authService = { postJob, useAddJob }
