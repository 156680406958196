import { IOption } from 'common/types/input.types'
import { ILazyParams } from 'common/types/table.types'
import { PrimeIcons } from 'primereact/api'
import { getCompanyFromAuthenticatedUser } from 'services/companies/companies.utils'
import { IFilter, IPageFilters } from 'services/filters/filters.types'
import { IGetJobResponse, IJobFilters } from 'services/jobs/jobs.types'
import { IFilterValue } from 'services/seafarers/seafarers.api'
import styled from 'styled-components'
import { jobContractTypeEnum, jobHiringLocationTypeEnum } from './jobs.enums'

export const StatusColorDiv = styled.span<{ variant: number }>`
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ theme, variant }) =>
    ({ 1: theme.colors.success, 2: theme.colors.warning, 0: theme.colors.error }[variant])};
  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const getSalaryCurrency = (salaryCurrency: 'USD' | 'EUR' | 'GBP'): string => {
  const currency = {
    USD: '$',
    EUR: '€',
    GBP: '£',
  }

  return currency[salaryCurrency] ?? PrimeIcons.MONEY_BILL
}
export const getSalaryIconCurrency = (salaryCurrency: 'USD' | 'EUR' | 'GBP'): string => {
  const currency = {
    USD: PrimeIcons.DOLLAR,
    EUR: PrimeIcons.EURO,
    GBP: PrimeIcons.POUND,
  }

  return currency[salaryCurrency] ?? PrimeIcons.MONEY_BILL
}

export const filterToOptions = (filters?: IFilter[]) => {
  return filters?.map(({ name, id }) => ({ label: name, value: id })) || []
}

export const createURLWithFilters = (filters?: IJobFilters | ILazyParams | IPageFilters): string => {
  let filtersToApply = ''
  let commonFilters = {
    status: <string | number>'',
    search: '',
    sortBy: 'createdAt:DESC',
    first: 0,
    rows: 6,
    page: 1,
    companyId: getCompanyFromAuthenticatedUser()?.id,
    filters: <{ [key: string]: IFilter[] | IOption[] } | null>{},
  }

  if (filters) {
    commonFilters = { ...commonFilters, ...filters }
    filtersToApply = `?page=${commonFilters.page}&limit=${commonFilters.rows}&filter.company.id=$eq:${commonFilters.companyId}`

    if (commonFilters.sortBy) {
      filtersToApply += `&sortBy=${commonFilters.sortBy}`
    }

    if (commonFilters?.status !== 'all' && commonFilters?.status) {
      filtersToApply += `&filter.status=$eq:${commonFilters?.status}`
    }

    if (commonFilters?.search) {
      filtersToApply += `&search=${commonFilters.search}`
    }

    if (Object.keys(commonFilters.filters!).length) {
      if (commonFilters.filters?.position?.length) {
        filtersToApply += `&filter.job.position.id=$in:${commonFilters.filters?.position.join(',')}`
      }

      if (commonFilters.filters?.experience?.length) {
        filtersToApply += `&filter.experience.id=$in:${commonFilters.filters?.experience.join(',')}`
      }

      if (commonFilters.filters?.vesselType?.length) {
        filtersToApply += `&filter.vesselFleets.id=$in:${commonFilters.filters?.vesselType.join(',')}`
      }

      if (commonFilters.filters?.category?.length) {
        filtersToApply += `&filter.job.category.id=$in:${commonFilters.filters?.category.join(',')}`
      }

      if (commonFilters.filters?.certificates?.length) {
        filtersToApply += `&filter.certificates.id=$in:${commonFilters.filters?.certificates.join(',')}`
      }

      if (commonFilters.filters?.certificates?.length) {
        filtersToApply += `&filter.certificates.id=$in:${commonFilters.filters?.certificates.join(',')}`
      }

      if (commonFilters.filters?.cv?.length) {
        filtersToApply += `&filter.curriculumVitae=${commonFilters.filters?.certificates.join(',')}`
      }
    }
  }

  return filtersToApply
}

export const waitAiGenerationText = `<p><strong>Loading</strong>&nbsp;<img src="https://cdn.pixabay.com/animation/2023/03/20/02/45/02-45-27-186_512.gif" height="16" width="16"></p><p><br></p><p><strong>Using AI magic to generate this text...</strong></p><p>It will take <strong>1 to 30 seconds</strong>, <strong>please use this time to fill in other job details</strong>.</p><p>Don't forget to come back and check the text before you <strong>PUBLISH</strong></p>`

const filterSuffix = {
  age: '=',
  nationality: '.id=$in:',
  position: '.id=$in:',
  vessel: '.id=$in:',
  englishLevel: '=$in:',
  jobStatus: '=$in:',
  dpLicense: '.filter.id=$in:',
  travelDocuments: '.filter.id=$in:',
  certifications: '.filter.id=$in:',
  endorsements: '.filter.id=$in:',
  totalWorkExperienceYears: '=$gte:',
  nearestAirport: '=',
  'experiences.imoNumber': '=',
  visas: '.id=$in:',
  cv: '=',
}

export const createFilters = (filters: { [key: string]: IFilter[] | IOption[] }) => {
  let filtersToApply = ''

  for (const key in filters) {
    const filterData = filters[key]
    const suffix = filterSuffix[key as keyof typeof filterSuffix]
    if (filterData && filterData.length) {
      if (Array.isArray(filterData)) {
        const values = filterData.map((item) => {
          if (key === 'totalWorkExperienceYears') {
            if (item.name?.includes('All')) {
              return 0
            }
            return item.name?.replace('+', '')
          } else if (typeof item === 'object') {
            return item.id || item.value || item.name
          }

          return item
        })

        if (values.length > 0) {
          filtersToApply += `&filter.${key !== 'cv' ? key : 'curriculumVitae'}${suffix}${values.join(',')}`
        }
      } else if (key === 'totalWorkExperienceYears') {
        filtersToApply += `&filter.${key}${suffix}${
          (filterData as IFilterValue).name === 'All' ? 0 : (filterData as IFilterValue).name.replace('+', '')
        }`
      } else {
        filtersToApply += `&filter.${key}${suffix}${(filterData as IFilterValue).id}`
      }
    }
  }

  return filtersToApply
}

export const createURLWithFiltersHiredPeople = (filters?: IJobFilters | ILazyParams | IPageFilters): string => {
  let filtersToApply = ''
  let commonFilters = {
    status: <string | number>'',
    sortBy: 'createdAt:DESC',
    first: 0,
    rows: 6,
    page: 1,
    companyId: getCompanyFromAuthenticatedUser()?.id,
    filters: <{ [key: string]: IFilter[] | IOption[] } | null>{},
    jobApplicationStatus: '',
  }

  if (filters) {
    commonFilters = { ...commonFilters, ...filters }
    filtersToApply = `?page=${commonFilters.page}&limit=${commonFilters.rows}&filter.hiresThroughSeaplify.company.id=$eq:${commonFilters.companyId}`

    if (commonFilters.sortBy) {
      filtersToApply += `&sortBy=${commonFilters.sortBy}`
    }

    if (commonFilters.status !== 'all' && commonFilters.status) {
      filtersToApply += `&filter.status=$eq:${commonFilters.status}`
    }

    if (commonFilters.jobApplicationStatus?.length) {
      filtersToApply += `&filter.jobApplications.status=$eq:${commonFilters.jobApplicationStatus}`
    }

    filtersToApply += createFilters(commonFilters.filters!)
  }

  return filtersToApply
}

export const parseJobToFormData = (job: IGetJobResponse) => {
  return {
    id: job?.id,
    title: job?.title,
    jobCategory: job?.jobCategory?.id ?? job?.jobCategory,
    position: job?.position?.id,
    description: job?.description,
    requiredDocuments: job?.requiredDocuments,
    hiringLocation: jobHiringLocationTypeEnum[job?.hiringLocation || 0],
    contractType: jobContractTypeEnum[job?.contractType || 0],
    jobAvailability: job?.jobAvailability ? new Date(job.jobAvailability) : '',
    signOnDate: job?.signOnDate ? new Date(job.signOnDate) : '',
    vessel_details: {
      vessel: job?.vessel?.id,
      vesselName: job?.vesselName,
      vesselEngineType: job?.vesselEngineType?.id,
      vesselYearBuild: job?.vesselYearBuild,
      vesselFlag: job?.vesselFlag?.id,
      teu: job?.teu ?? '',
      bhp: job?.bhp ?? '',
      kw: job?.kw ?? '',
      dwt: job?.dwt ?? '',
      gt: job?.gt ?? '',
    },
    salary: {
      salaryCurrency: job?.salaryCurrency,
      salaryFrom: job?.salaryFrom,
      salaryTo: job?.salaryTo,
    },
    min_requirements: {
      totalExperience: job?.totalExperience?.id,
      languages: job?.languages ? job.languages?.map((elem: any) => elem.id) : undefined,
      visas: job?.visas?.length ? job?.visas?.map((elem: any) => elem.id) : undefined,
      travelDocuments: job?.travelDocuments?.length ? job?.travelDocuments?.map((elem: any) => elem.id) : undefined,
      candidatesAvailabilityOptions: job?.candidatesAvailabilityOptions?.length
        ? job?.candidatesAvailabilityOptions?.map((elem: any) => elem.id)
        : undefined,
      candidateStatusOptions: job?.candidateStatusOptions?.length
        ? job?.candidateStatusOptions?.map((elem: any) => elem.id)
        : undefined,
      certificates: job?.certificates?.length ? job?.certificates?.map((elem: any) => elem.id) : undefined,
      dpLicences: job?.dpLicences?.length ? job?.dpLicences?.map((elem: any) => elem.id) : undefined,
      endorsements: job?.endorsements?.length ? job?.endorsements?.map((elem: any) => elem.id) : undefined,
      mainEngineTypes: job?.mainEngineTypes?.length ? job?.mainEngineTypes?.map((elem: any) => elem.id) : undefined,
    },
    hiringMultiple: job?.hiringMultiple,
    hiringMultipleCount: job?.hiringMultipleCount,
    acceptedNationalities: job?.acceptedNationalities?.map((elem: any) => elem.id),
  }
}

export const generateJobTitleForSEO = (job: IGetJobResponse) => {
  const salary =
    job.salaryTo || job.salaryFrom
      ? `, salary: ${getSalaryCurrency(job.salaryCurrency)}${job.salaryTo || job.salaryFrom}`
      : ''

  let title = `${job.title} at ${job.company.name}${salary} | Seaplify | `
  title += `fleet: ${job.jobCategory.name}`
  // title += `fleet: ${job.jobCategory.name}, position: ${job.position.name}`
  if (job.vesselName) title += `, vessel: ${job.vesselName}`
  if (job.vessel) title += `, ${job.vessel.name}`
  if (job.vesselEngineType) title += `, ${job.vesselEngineType.name}`
  return title
}
