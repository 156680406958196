import axios, { AxiosResponse } from 'axios'
import cookie from 'cookie'
import { IncomingMessage, ServerResponse } from 'http'
import { jwtDecode } from 'jwt-decode'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { ApiPathsUsers } from './auth.service'
import { IUser } from './auth.types'

class Auth {
  private _getSessionTokenFn!: null | Promise<AxiosResponse<{ token: string }, any>>

  public readonly clearSessionCookie = (res: ServerResponse) => {
    res.setHeader(
      'Set-Cookie',
      cookie.serialize('user-data', '', {
        httpOnly: true,
        secure: process.env.NODE_ENV !== 'development',
        expires: new Date(0),
        sameSite: 'strict',
        path: '/',
      }),
    )
  }

  public getServerToken = async (
    req: IncomingMessage & {
      cookies: NextApiRequestCookies
    },
    res: ServerResponse,
  ) => {
    const userCookie = req.cookies?.['user-data'] ?? ''

    if (!userCookie) {
      return null
    }

    const parsedUserSessionCookie: IUser | Record<string, never> = userCookie ? JSON.parse(userCookie) : {}

    const accessToken = parsedUserSessionCookie?.tokens?.accessToken ?? null

    const { exp } = jwtDecode(accessToken)
    const isAccessTokenExpired = Date.now() / 1000 > <number>exp
    const refreshToken = parsedUserSessionCookie.tokens.refreshToken

    if (!isAccessTokenExpired) return parsedUserSessionCookie.tokens.accessToken

    // - Fetch new access token if is expired
    try {
      //  It can be REST API or GraphQL
      const newTokens = await this.getNewTokens(refreshToken)

      const newSessionCookieObj = {
        ...parsedUserSessionCookie,
        tokens: newTokens,
      }

      // const expiresDate = <number>jwtDecode(newTokens.refreshToken).exp
      // const expires = new Date(expiresDate * 1000) // Convert to milliseconds
      res.setHeader(
        'Set-Cookie',
        cookie.serialize('user-data', JSON.stringify(newSessionCookieObj), {
          httpOnly: true,
          secure: process.env.NODE_ENV !== 'development',
          // expires,
          sameSite: 'strict',
          path: '/',
        }),
      )

      return newTokens.accessToken
    } catch (error) {
      console.log('cleared session cookie from getServerToken')
      this.clearSessionCookie(res)
    }
  }

  public readonly getSessionToken = async () => {
    if (!this._getSessionTokenFn) {
      this._getSessionTokenFn = axios.get<{ token: string }>('/api/get-session-token', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
    const res = await this._getSessionTokenFn
    this._getSessionTokenFn = null

    return res.data.token
  }

  public readonly setSession = async (userSession: IUser) => {
    const { tokens, refreshToken, ...userData } = userSession
    localStorage.setItem('user-data', JSON.stringify(userData))

    return await axios.post('/api/set-session', userSession, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  public readonly clientLogout = async () => {
    await this.logout()
    localStorage.removeItem('user-data')
  }

  public readonly logout = async () => {
    return await axios.post('/api/logout')
  }

  public readonly getNewTokens = async (refreshToken: string) => {
    const resp = await axios.get<
      undefined,
      {
        data: {
          accessToken: string
          refreshToken: string
        }
      }
    >(`${process.env.NEXT_PUBLIC_SALIAJOB_API_BASE_URL}/${ApiPathsUsers.tokenRefresh}`, {
      headers: { Accept: 'application/json', Authorization: `Bearer ${refreshToken}` },
    })
    return resp.data
  }

  public readonly getAuthenticatedUser = (): IUser | null => {
    const user = typeof localStorage !== 'undefined' ? (localStorage.getItem('user-data') as string) : 'null'

    return JSON.parse(user)
  }
}

export const auth = new Auth()
